import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

const SignUp = ({ toggleNew, AppState }) => {
  const { signUp } = AppState;

  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const [vPassword, changeVPassword] = useState('');

  const [charAmount, changeCharAmount] = useState('invalid');
  const [cap, changeCap] = useState('invalid');
  const [num, changeNum] = useState('invalid');
  const [matching, changeMatching] = useState('invalid');

  function validatePassword() {
    password.length >= 6
      ? changeCharAmount('valid')
      : changeCharAmount('invalid');
    password.match(/[A-Z]/) ? changeCap('valid') : changeCap('invalid');
    password.match(/[0-9]/) ? changeNum('valid') : changeNum('invalid');
  }

  function checkMatching() {
    if (charAmount === 'valid') {
      password === vPassword
        ? changeMatching('valid')
        : changeMatching('invalid');
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (
      matching === 'valid' &&
      charAmount === 'valid' &&
      cap === 'valid' &&
      num === 'valid'
    ) {
      signUp(name, email, password);
    }
  };

  useEffect(() => {
    validatePassword();
    checkMatching();
  });

  return (
    <SignInContainer>
      <span>Sign up</span>
      <form onSubmit={handleSubmit}>
        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          type="text"
          maxLength="12"
          required
          onChange={e => changeName(e.target.value)}
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          required
          onChange={e => changeEmail(e.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          value={password}
          required
          pattern="(?=.*\d)(?=.*[A-Z]).{6,}"
          onChange={e => changePassword(e.target.value)}
        />
        <label htmlFor="verifyPassword">Verify Password</label>
        <input
          id="verifyPassword"
          type="password"
          value={vPassword}
          required
          pattern="(?=.*\d)(?=.*[A-Z]).{6,}"
          onChange={e => changeVPassword(e.target.value)}
        />
        <ul>
          <li className={charAmount}>6 characters</li>
          <li className={cap}>1 capitol</li>
          <li className={num}>1 number</li>
          <li className={matching}>matches</li>
        </ul>
        <input type="submit" value="Sign up!" />
      </form>
      <input
        type="button"
        value="Back"
        id="back"
        onClick={() => toggleNew(false)}
      />
    </SignInContainer>
  );
};

SignUp.propTypes = {
  toggleNew: PropTypes.func,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(SignUp));

const SignInContainer = styled.div`
  ${props => props.theme.signIn}
  & input[type='button'], input[type='submit'] {
    ${props => props.theme.button}
  }
`;
