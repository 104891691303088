import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import AppIndex from './components/app/AppIndex';
import Auth from './components/auth/Auth';

function App({ AppState }) {
  const { user } = AppState;
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      {user ? <AppIndex /> : <Auth />}
    </ThemeProvider>
  );
}

App.propTypes = {
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(App));

const Theme = {
  card: `
    border-radius: var(--rounded);
    padding: var(--main-margin);
    background: var(--secondary-color);
    border: none;
    width: 100%;
    text-align: left;
    font-weight: 500;
    `,

  // progress: `
  //   border-radius: var(--rounded)
  //   width: 100%;
  //   height: 5px;
  //   background-color: var(--secondary-color);
  //   `,

  button: `
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    background-color: var(--theme-color);
    color: white;
    border: none;
    border-radius: var(--rounded);
    padding: 0.2em 0.4em;
    cursor: pointer;
    font-size: 1em;
    font-weight: 400;
    &:focus {
      outline: none;
    }
    `,

  signIn: `
  form {
    margin-bottom: 1em;
    & label {
      display: block;
    }
    & input[type='text'],
    & input[type='email'],
    & input[type='password'] {
      margin-bottom: 1rem;
      font-size: 1.2rem;
      color: black;
      border: none;
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 0.2em 0.2em;
      background: var(--button-group-color);
    }
    & input[type='submit'] {
      width: 100%;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 1em;
    & li {
      margin: none;
      padding: none;
    }
  }

  input[type='button'] {
    width: 100%;
  }

  .invalid {
    color: var(--secondary-theme-color);
  }

  .valid {
    display: none;
  }

  span {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  #back {
    background-color: var(--secondary-theme-color);
    margin-bottom: 1em;
  }
`,
};

const GlobalStyle = createGlobalStyle`
  :root {
    --main-color: rgb(240, 240, 240);
    --secondary-color: rgb(255, 255, 255);
    --button-group-color: rgb(210, 210, 210);
    --options-background-color: rgba(225, 225, 225);

    --theme-color: rgb(0, 200, 150);
    --secondary-theme-color: rgb(255, 80, 90);

    --checkbox-color: rgb(240, 240, 240);

    --main-font-color: rgb(0, 0, 0);
    --secondary-font-color: rgb(156, 156, 156);

    --main-margin: 0.8rem;
    --secondary-margin: 0.4rem;
    --rounded: 10px;
    @media (prefers-color-scheme: dark) {
      --main-color: rgb(10, 10, 10);
      --secondary-color: rgb(40, 40, 40);
      --button-group-color: rgb(40, 42, 54);
      --options-background-color: rgb(5, 6, 10);

      --checkbox-color: rgb(75, 75, 75);

      --main-font-color: rgb(255, 255, 255);
      --secondary-font-color: rgb(110, 110, 110);
    }
  }

  * {
    margin: 0;
    padding: 0px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--main-font-color);
  }

  body {
    background-color: var(--main-color);
  }

  ul {
    list-style: none;
  }

  button {
    background: transparent;
    border: none;
    outline: none;
  }

  h1 {
    font-size: 1.4em;
    font-weight: bolder;
  }

  input[type='button'],
  input[type='submit'] {
  }

  .hidden {
    display: none;
  }

  progress {
    border-radius: var(--rounded);
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--main-color);
    &::-webkit-progress-bar {
      background-color: var(--secondary-color);
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      border-radius: 5px;
      background-color: var(--theme-color);
      -webkit-transition: 0.2s ease-out all;
      transition: 0.2s ease-out all;
    }
  }
`;
