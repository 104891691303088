import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import SignIn from '../../auth/SignIn';
import SignUp from '../../auth/SignUp';

const Menu = ({ AppState }) => {
  const { user, toggleMenu, signOut } = AppState;

  const [newUser, changeNew] = useState(false);
  return (
    <MenuSideNav>
      {user === null ? (
        newUser ? (
          <SignUp changeNew={changeNew} />
        ) : (
          <SignIn changeNew={changeNew} />
        )
      ) : (
        <div>
          <input type="button" id="close" value="close" onClick={toggleMenu} />
          <input
            type="button"
            id="signOut"
            value="sign out"
            onClick={() => signOut()}
          />
          <span className="name">{user.displayName}</span>
          <span className="email">{user.email}</span>
        </div>
      )}
    </MenuSideNav>
  );
};

Menu.propTypes = {
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(Menu));

const MenuSideNav = styled.div`
  position: absolute;
  height: 100vh;
  width: 20rem;
  z-index: 3;
  right: 0;
  top: 0;
  background-color: var(--secondary-color);
  padding: 1rem 0.5rem;
  & input[type='button'] {
    width: 100%;
    margin-bottom: 1rem;
    ${props => props.theme.button}
  }
  & span {
    display: block;
  }
  & .name {
    font-size: 1.4em;
    font-weight: bold;
  }
`;
