import { observable, action, decorate } from 'mobx';
import { quickId } from 'quickids';
import firebase from './config/firebase';

class ApplicationState {
  constructor() {
    this.getInfo();
  }

  loading = true;
  projects = [];
  todoLists = [];
  openProject = null;
  openProjectId = null;
  user = null;
  menu = false;

  getInfo = () => {
    this.loading = true;
    function compare(a, b) {
      if (a.due < b.due) {
        return -1;
      } else if (a.due > b.due) {
        return 1;
      } else return 0;
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user = user;
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('projects')
          .onSnapshot(snapshot => {
            this.projects = snapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort(compare);
          });
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('todoLists')
          .onSnapshot(snapshot => {
            this.todoLists = snapshot.docs
              .map(doc => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort(compare);
          });
      } else {
        this.user = null;
        this.projects = [];
        this.todoLists = [];
        this.openProject = null;
        this.openProjectId = null;
      }
      this.loading = false;
    });
  };

  // ! auth actions ////////////////////////////////////////////////////////////

  signIn = async (email, password) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      alert(error);
    }
  };

  signUp = async (name, email, password) => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      this.initUser(email, name);

      return firebase.auth().currentUser.updateProfile({
        displayName: name,
      });
    } catch (error) {
      alert(error);
    }
  };

  signOut = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      alert(error);
    }
  };

  initUser = (email, name) => {
    const currentUser = firebase.auth().currentUser;
    const uid = currentUser.uid;
    firebase.firestore().collection('users').doc(uid).set({
      name,
      email,
    });
  };

  // ! project actions ////////////////////////////////////////////////////////////

  changeOpenProject = project => {
    this.openProject = project;
    this.openProjectId = project.id;
  };

  addProject = project => {
    firebase
      .firestore()
      .collection('users')
      .doc(this.user.uid)
      .collection('projects')
      .doc()
      .set(project);
  };

  getProjectProgress = projectId => {
    let total = 0;
    let completed = 0;
    const project = this.projects.find(project => project.id === projectId);
    project.todoLists.forEach(list => {
      total += list.todos.length;
      list.todos.forEach(todo => {
        if (todo.completed) {
          completed++;
        }
      });
    });
    return { total, completed };
  };

  removeProject = id => {
    if (id === this.openProjectId) {
      this.openProjectId = null;
    }
    firebase
      .firestore()
      .collection('users')
      .doc(this.user.uid)
      .collection('projects')
      .doc(id)
      .delete();
  };

  editProject = (id, title, due) => {
    firebase
      .firestore()
      .collection('users')
      .doc(this.user.uid)
      .collection('projects')
      .doc(id)
      .update({
        title,
        due,
      });
  };

  // ! todo-list actions ////////////////////////////////////////////////////////////

  addSimpleTodo = todoList => {
    firebase
      .firestore()
      .collection('users')
      .doc(this.user.uid)
      .collection('todoLists')
      .doc()
      .set(todoList);
  };

  // ! list actions ////////////////////////////////////////////////////////////

  addList = (projectId, list) => {
    const id = quickId();
    firebase
      .firestore()
      .collection('users')
      .doc(this.user.uid)
      .collection('projects')
      .doc(projectId)
      .update({
        todoLists: firebase.firestore.FieldValue.arrayUnion({
          completed: false,
          collapsed: false,
          title: list,
          todos: [],
          id: id,
        }),
      });
  };

  collapseList = (projectId, listId) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.forEach(list => {
      if (list.id === listId) {
        list.collapsed = !list.collapsed;
      }
    });
    this.updateNewList(projectId, list);
  };

  addListDescription = (projectId, listId, description) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.forEach(list => {
      if (list.id === listId) {
        list.description = description;
      }
    });
    this.updateNewList(projectId, list);
  };

  removeList = (projectId, listId) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists = list.todoLists.filter(list => list.id !== listId);
    this.updateNewList(projectId, list);
  };

  renameList = (projectId, listId, title) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.forEach(list => {
      if (list.id === listId) {
        list.title = title;
      }
    });
    this.updateNewList(projectId, list);
  };

  combineList = (projectId, listIdOne, listIdTwo) => {
    const list = this.projects.find(project => project.id === projectId);
    const listOne = list.todoLists.find(list => list.id === listIdOne);
    const listTwo = list.todoLists.find(list => list.id === listIdTwo);
    const combineItems = listOne.todos.concat(listTwo.todos);
    list.todoLists.forEach(list => {
      if (list.id === listIdOne) {
        list.todos = combineItems;
      }
    });
    const todoList = list.todoLists.filter(list => list.id !== listIdTwo);
    list.todoLists = todoList;
    this.updateNewList(projectId, list);
  };

  changeListDue = (projectId, listId, date) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.forEach(list => {
      if (list.id === listId) {
        list.due = date;
      }
    });
    this.updateNewList(projectId, list);
  };

  completeAllTodos = (projectId, listId, value) => {
    const project = this.projects.find(project => project.id === projectId);
    project.todoLists.forEach(list => {
      if (list.id === listId) {
        list.todos.forEach(todo => {
          todo.completed = value;
        });
        list.completed = this.checkListCompleted(list);
      }
    });

    project.completed = this.checkProjectCompleted(project);
    this.updateNewList(projectId, project);
  };

  // ! todo actions ////////////////////////////////////////////////////////////

  addTodo = (projectId, listId, todo) => {
    const id = quickId();
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.find(
      list =>
        list.id === listId &&
        list.todos.push({ title: todo, completed: false, id: id })
    );
    this.updateNewList(projectId, list);
  };

  removeTodo = (projectId, listId, todoId) => {
    const list = this.projects.find(project => project.id === projectId);
    const todoList = list.todoLists.find(list => list.id === listId);
    const todos = todoList.todos.filter(todo => todo.id !== todoId);
    list.todoLists.forEach(list => {
      if (list.id === listId) {
        list.todos = todos;
      }
    });
    this.updateNewList(projectId, list);
  };

  changeTodoDue = (projectId, listId, todoId, date) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.forEach(list => {
      list.id === listId &&
        list.todos.forEach(todo => {
          if (todo.id === todoId) {
            todo.due = date;
          }
        });
    });
    this.updateNewList(projectId, list);
  };

  renameTodo = (projectId, listId, todoId, title) => {
    const list = this.projects.find(project => project.id === projectId);
    list.todoLists.forEach(list => {
      list.id === listId &&
        list.todos.forEach(todo => {
          if (todo.id === todoId) {
            todo.title = title;
          }
        });
    });
    this.updateNewList(projectId, list);
  };

  completeTodo = (projectId, listId, todoId) => {
    const project = this.projects.find(project => project.id === projectId);
    project.todoLists.forEach(list => {
      if (list.id === listId) {
        list.todos.forEach(todo => {
          if (todo.id === todoId) {
            todo.completed = !todo.completed;
          }
        });
        list.completed = this.checkListCompleted(list);
      }
    });
    project.completed = this.checkProjectCompleted(project);
    this.updateNewList(projectId, project);
  };

  toggleMenu = () => {
    this.menu = !this.menu;
  };

  // ! this.functions ////////////////////////////////////////////////////////////

  updateNewList = async (projectId, list) => {
    await firebase
      .firestore()
      .collection('users')
      .doc(this.user.uid)
      .collection('projects')
      .doc(projectId)
      .update(list);
  };

  checkListCompleted = list => {
    if (
      list.todos.filter(todo => !todo.completed).length === 0 &&
      !list.completed
    ) {
      return true;
    } else if (
      list.todos.filter(todo => !todo.completed).length > 0 &&
      list.completed
    ) {
      return false;
    }
    return false;
  };

  checkProjectCompleted = project => {
    if (
      project.todoLists.filter(list => !list.completed).length === 0 &&
      !project.completed
    ) {
      return true;
    } else if (
      project.todoLists.filter(list => !list.completed).length > 0 &&
      project.completed
    ) {
      return false;
    }
    return false;
  };
}

decorate(ApplicationState, {
  user: observable,
  getInfo: action,
  signIn: action,
  signUp: action,
  signOut: action,

  projects: observable,
  openProject: observable,
  openProjectId: observable,
  changeOpenProject: action,
  addProject: action,
  removeProject: action,
  getProjectProgress: action,
  editProject: action,

  addSimpleTodo: action,
  todoLists: observable,

  menu: observable,
  toggleMenu: action,

  addList: action,
  removeList: action,
  renameList: action,
  combineList: action,
  addListDescription: action,
  collapseList: action,

  addTodo: action,
  removeTodo: action,
  changeTodoDue: action,
  renameTodo: action,
  completeTodo: action,
  completeAllTodos: action,
});

export default new ApplicationState();
