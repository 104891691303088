import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

const SignIn = ({ toggleNew, AppState }) => {
  const { signIn } = AppState;

  const handleSubmit = e => {
    const { email, password } = e.target.elements;
    e.preventDefault();
    signIn(email.value, password.value);
  };

  return (
    <SignInContainer>
      <span>Sign In</span>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input id="email" type="email" />
        <label htmlFor="password">Password</label>
        <input id="password" type="password" />
        <input type="submit" value="Sign in" />
      </form>
      <span>Don&#39;t have an account?</span>
      <input type="button" value="Sign up" onClick={() => toggleNew(true)} />
    </SignInContainer>
  );
};

SignIn.propTypes = {
  toggleNew: PropTypes.func,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(SignIn));

const SignInContainer = styled.div`
  ${props => props.theme.signIn}
  & input[type='button'], input[type='submit'] {
    ${props => props.theme.button}
  }
`;
