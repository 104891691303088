import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBars } from '@fortawesome/free-solid-svg-icons';

import Add from './Add';
import Menu from './Menu';

const ToolBar = ({ AppState }) => {
  const { user, menu, toggleMenu } = AppState;

  const [displayAdd, toggleAdd] = useState(false);

  const toggle = () => {
    toggleAdd(!displayAdd);
  };

  return (
    <Toolbar>
      {/* <DayNight /> */}
      <AddButton onClick={() => toggle()}>
        <FontAwesomeIcon icon={faPlus} />
      </AddButton>
      {displayAdd && <Add toggle={toggle} />}
      <User>
        {user !== null && (
          <UserName className="name">{user.displayName}</UserName>
        )}
        <Bars onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </Bars>
      </User>
      {menu && <Menu />}
    </Toolbar>
  );
};

ToolBar.propTypes = {
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(ToolBar));

const Toolbar = styled.div`
  width: 100vw;
  position: relative;
  background: var(--theme-color);
`;

const User = styled.div`
  display: table;
  position: absolute;
  right: 1rem;
  top: 0;
`;

const UserName = styled.span`
  padding: 0;
  margin: 0;
  padding-right: 1rem;
  color: black;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
`;

const Bars = styled.button`
  font-size: 2em;
  line-height: 2.5rem;
  outline: none;
  display: inline;
  & svg path {
    fill: black;
  }
  &:hover {
    cursor: pointer;
  }
`;

const AddButton = styled.button`
  font-size: 2em;
  line-height: 2.5rem;
  margin: 0 auto;
  display: block;
  & svg path {
    fill: black;
  }
  &:hover {
    cursor: pointer;
  }
`;
