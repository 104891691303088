import React, { useState } from 'react';
import styled from 'styled-components';

import SignIn from './SignIn';
import SignUp from './SignUp';

const Auth = () => {
  const [newUser, toggleNew] = useState(false);

  return (
    <AuthContainer>
      {newUser ? (
        <SignUp toggleNew={toggleNew} />
      ) : (
        <SignIn toggleNew={toggleNew} />
      )}
    </AuthContainer>
  );
};

export default Auth;

const AuthContainer = styled.div`
  width: 80%;
  // border: 1px solid black;
  margin: 4rem auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 1rem;
  background: var(--secondary-color);
  @media screen and (min-width: 768px) {
    width: 30%;
  }
`;
