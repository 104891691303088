import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import CalendarInput from '../../../CalendarInput';

const ListOptions = ({
  listId,
  toggleOptions,
  projectId,
  completed,
  currentDescription,
  AppState,
}) => {
  const {
    openProject,
    removeList,
    combineList,
    completeAllTodos,
    changeListDue,
    addListDescription,
  } = AppState;

  const [showingList, toggleList] = useState(false);
  const [description, updateDescription] = useState(currentDescription);
  const [editingDescription, toggleEditingDesc] = useState(false);
  const [calendar, toggleCalendar] = useState(false);

  function handleDue(date) {
    const submitDate = new Date(date);
    submitDate.setHours(23, 59);
    changeListDue(projectId, listId, submitDate.getTime());
  }

  const handleSubmit = e => {
    e.preventDefault();
    addListDescription(projectId, listId, description);
    toggleEditingDesc(!editingDescription);
  };

  return (
    <OptionsMenu onMouseLeave={() => toggleOptions(false)}>
      <button onClick={() => toggleEditingDesc(!editingDescription)}>
        description
      </button>
      <button onClick={() => toggleCalendar(!calendar)}>due date</button>
      {editingDescription && (
        <form onSubmit={handleSubmit}>
          <textarea
            value={description}
            onChange={e => updateDescription(e.target.value)}
          />
          <input type="submit" value="add" />
        </form>
      )}
      {calendar && (
        <CalendarInput
          showing={calendar}
          handleDue={handleDue}
          toggle={toggleCalendar}
        />
      )}
      {completed === 1 ? (
        <button onClick={() => completeAllTodos(projectId, listId, false)}>
          mark all incomplete
        </button>
      ) : (
        <button onClick={() => completeAllTodos(projectId, listId, true)}>
          mark all complete
        </button>
      )}
      <button onClick={() => removeList(projectId, listId)}>remove</button>
      {openProject.todoLists.length > 1 && (
        <button onClick={() => toggleList(true)}>combine list...</button>
      )}
      {showingList && (
        <ProjectList>
          {openProject.todoLists.map(
            list =>
              list.id !== listId && (
                <button onClick={() => combineList(projectId, list.id, listId)}>
                  {list.title}
                </button>
              )
          )}
        </ProjectList>
      )}
    </OptionsMenu>
  );
};

ListOptions.propTypes = {
  listId: PropTypes.string,
  toggleOptions: PropTypes.func,
  projectId: PropTypes.string,
  completed: PropTypes.number,
  currentDescription: PropTypes.string,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(ListOptions));

const OptionsMenu = styled.div`
  background: var(--main-color);
  padding: 0.6rem;
  position: absolute;
  z-index: 2;
  top: -0.6rem;
  right: calc(100% - 2rem);
  border-radius: 5px;
  display: inline-block;
  & button {
    text-align: right;
    display: block;
    white-space: nowrap;
    width: 100%;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      color: var(--theme-color);
    }
  }
  & svg path {
    fill: gray;
  }
`;

const ProjectList = styled.div`
  padding-left: 0.4rem;
`;
