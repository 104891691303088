import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyDpEILEk9Jmwy2xarMWis0od2DCWayIF84',
  authDomain: 'kemplet.firebaseapp.com',
  databaseURL: 'https://kemplet.firebaseio.com',
  projectId: 'kemplet',
  storageBucket: 'kemplet.appspot.com',
  messagingSenderId: '86090933157',
  appId: '1:86090933157:web:665c908a27452991437e4c',
  measurementId: 'G-LZL1H1F5G3',
};

firebase.initializeApp(config);
firebase.auth();
firebase.firestore();
// firebase.analytics();

export default firebase;
