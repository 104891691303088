import React, { useState } from 'react';
import styled from 'styled-components';

import TodayToolTip from './TodayToolTip';

const TodayDue = ({ type, due, items }) => {
  const [toolTip, toggleToolTip] = useState(false);
  return (
    <ListItem>
      <Badge
        late={!due}
        onMouseOver={() => toggleToolTip(true)}
        onMouseLeave={() => toggleToolTip(false)}
      >
        {toolTip && <TodayToolTip items={items} />}
        <Number late={!due}>{items.length}</Number>
      </Badge>
      <Title>{`${type}${items.length > 1 ? 's' : ''}`}</Title>
      {/* <span className="dueTitle">Late</span>
          <ul>
            {dueLate.map(task => (
              <li key={task.id}>{task.title}</li>
            ))}
          </ul> */}
    </ListItem>
  );
};

export default TodayDue;

const ListItem = styled.div`
  margin: var(--secondary-margin) 0;
`;

const Title = styled.span`
  display: block;
  display: inline-block;
`;

const Badge = styled.div`
  position: relative;
  text-align: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 3px 0;
  margin-right: 0.4rem;
  display: inline-block;
  background-color: ${props =>
    props.late ? 'rgba(255, 80, 90, 0.15)' : 'rgba(0, 200, 150, 0.15)'};
`;

const Number = styled.div`
  margin: 0;
  display: block;
  font-weight: bold;
  pointer-events: none;
  color: ${props =>
    props.late ? 'var(--secondary-theme-color)' : 'var(--theme-color)'};
`;
