import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import ToolBar from './toolbar/ToolBar';
import Today from './Today';
import Projects from './content/projects/Projects';
// import TodoLists from './TodoLists';
import TaskDetail from './content/projects/ProjectDetail';
// import Calendar from './Calendar';

const AppIndex = ({ AppState }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { openProjectId } = AppState;
  return (
    <div className="App">
      <ToolBar />
      <Content>
        <Tasks>
          <TaskContent>
            <TaskList>
              <Today />
              <ProjectTitle>Projects</ProjectTitle>
              <Projects />
              {/* <h1>Todos Lists</h1>
                <TodoLists /> */}
            </TaskList>
            {openProjectId && <TaskDetail />}
          </TaskContent>
        </Tasks>
        {/* <Calendar /> */}
      </Content>
    </div>
  );
};

AppIndex.propTypes = {
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(AppIndex));

const Content = styled.div`
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ProjectTitle = styled.h1`
  margin-bottom: var(--main-margin);
`;

const Tasks = styled.div`
  display: flex;
  background: var(--main-color);
  overflow: hidden;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: 3;
  height: calc(100vh - 2.5rem);
  padding: var(--main-margin);
`;

const TaskContent = styled.div`
  overflow: scroll;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--main-margin);
    height: 100%;
  }
`;

const TaskList = styled.div`
  @media screen and (min-width: 768px) {
    overflow: scroll;
  }
`;
