import React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

const TodayToolTip = ({ AppState, items }) => {
  const { changeOpenProject } = AppState;
  return (
    <ToolTip>
      <ToolTipContent>
        {items.map(item =>
          item.project ? (
            <TitleButton onClick={() => changeOpenProject(item.project)}>
              {item.list.title}
            </TitleButton>
          ) : (
            <TitleButton onClick={() => changeOpenProject(item)}>
              {item.title}
            </TitleButton>
          )
        )}
      </ToolTipContent>
    </ToolTip>
  );
};

export default inject('AppState')(observer(TodayToolTip));

const ToolTip = styled.div`
  position: absolute;
  left: 99%;
  top: -0.2rem;
  z-index: 2;
`;

const ToolTipContent = styled.div`
  background: var(--options-background-color);
  margin-left: 0.4rem;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: calc(100% - 0.4rem);
    top: calc(0.2rem + 6px);
    width: 0;
    height: 0;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 8px solid var(--options-background-color);
  }
`;

const TitleButton = styled.div`
  white-space: nowrap;
  display: block;
  cursor: pointer;
  &:hover {
    color: var(--theme-color);
  }
`;
