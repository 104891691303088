import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

import checkmark from '../../../../../images/checkmark.svg';

const Todo = ({ todo, projectId, listId, AppState }) => {
  const { completeTodo, renameTodo, removeTodo } = AppState;

  const [editing, toggleEditing] = useState(false);
  // const [options, toggleOptions] = useState(false);
  const [todoInput, updateTodoInput] = useState(todo.title);

  const handleSubmit = e => {
    e.preventDefault();
    toggleEditing(false);
    renameTodo(projectId, listId, todo.id, todoInput);
  };

  return (
    <TodoCheck>
      <input
        type="checkbox"
        readOnly
        checked={todo.completed}
        onClick={() => completeTodo(projectId, listId, todo.id)}
      />
      {editing ? (
        <TodoForm onSubmit={handleSubmit} onBlur={() => toggleEditing(false)}>
          <EditInput
            type="text"
            value={todoInput}
            autoFocus
            onChange={e => updateTodoInput(e.target.value)}
            // onBlur={() => toggleEditing(false)}
          />
          {/* editing buttons */}
        </TodoForm>
      ) : (
        <label onClick={() => toggleEditing(true)}>{todo.title}</label>
      )}
      {todo.due && (
        <TodoDue>
          {`${new Date(todo.due).getMonth() + 1}/${new Date(
            todo.due
          ).getDate()}`}
        </TodoDue>
      )}
      <TodoOptions>
        <button onClick={() => removeTodo(projectId, listId, todo.id)}>
          <FontAwesomeIcon icon={faMinusCircle} />
        </button>
      </TodoOptions>
    </TodoCheck>
  );
};

Todo.propTypes = {
  todo: PropTypes.object,
  projectId: PropTypes.string,
  listId: PropTypes.string,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(Todo));

const TodoOptions = styled.div`
  position: absolute;
  right: 0;
  display: inline-block;
  opacity: 0;
  & button {
    cursor: pointer;
    &:hover {
      & svg path {
        fill: var(--secondary-theme-color);
        transition: fill 0.3s linear;
        -webkit-transition: fill 0.3s linear;
        -moz-transition: fill 0.3s linear;
        -ms-transition: fill 0.3s linear;
        -o-transition: fill 0.3s linear;
      }
    }
  }
`;

const TodoCheck = styled.li`
  margin: 0.4em 0.8rem;
  position: relative;
  &:hover ${TodoOptions} {
    opacity: 1;
  }
  & input[type='checkbox'] {
    opacity: 0;
    cursor: pointer;
    // margin-right: 0.4rem;
    height: 1em;
    width: 1em;
    position: absolute;
    top: 0.2em;
    z-index: 2;
    &:checked {
      & + label::after {
        content: url(${checkmark});
        color: var(--theme-color);
      }
      & + label {
        text-decoration: line-through;
        color: var(--secondary-font-color);
      }
    }
  }
  & label {
    font-weight: normal;
    word-break: break-word;
    margin-left: 1.4rem;
    cursor: text;
  }
  position: relative;
  & label::before,
  & label::after {
    display: inline-block;
    height: 1em;
    width: 1em;
    position: absolute;
    top: 0.2em;
    border-radius: 2px;
    pointer-events: none;
  }
  & label::before {
    background: var(--checkbox-color);
    content: '';
    left: 0rem;
  }
  & label::after {
    content: none;
    left: 0.3rem;
  }
`;

const TodoForm = styled.form`
  border: none;
  display: inline;
  margin: 0;
`;

const EditInput = styled.input`
  outline: none;
  border: none;
  overflow: visible;
  resize: none;
  background-color: transparent;
  margin-left: 1.4rem;
  width: calc(100% - 2.6rem);
`;

const TodoDue = styled.span`
  display: inline;
  font-size: 0.8rem;
  color: gray;
  margin-left: 0.6rem;
`;
