import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import TodayDue from './TodayDue';

const Today = ({ AppState }) => {
  const { projects } = AppState;

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const dueProjects = projects.filter(project => {
    return (
      project.due !== '' &&
      project.due > new Date().getTime() &&
      new Date(project.due).toDateString() === new Date().toDateString() &&
      !project.completed
    );
  });

  const dueLists = () => {
    const returnArr = [];
    projects.forEach(project => {
      project.todoLists.length > 0 &&
        project.todoLists.forEach(list => {
          if (
            list.due &&
            list.due > new Date().getTime() &&
            new Date(list.due).toDateString() === new Date().toDateString() &&
            !list.completed
          ) {
            returnArr.push({ project, list });
          }
        });
    });
    return returnArr;
  };

  const lateProjects = projects.filter(project => {
    return (
      project.due !== '' &&
      project.due < new Date().getTime() &&
      !project.completed
    );
  });

  const lateLists = () => {
    const returnArr = [];
    projects.forEach(project => {
      project.todoLists.length > 0 &&
        project.todoLists.forEach(list => {
          if (list.due && list.due < new Date().getTime() && !list.completed) {
            returnArr.push({ project, list });
          }
        });
    });
    return returnArr;
  };

  const date = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date());
  return (
    <TodayCard>
      <h1>{`${date}`}</h1>
      <DueLists>
        <List>
          <TodayDue type="Project List" due={true} items={dueLists()} />
          <TodayDue type="Project" due={true} items={dueProjects} />
        </List>
        <List>
          {lateLists().length > 0 && (
            <TodayDue type="Project List" due={false} items={lateLists()} />
          )}
          {lateProjects.length > 0 && (
            <TodayDue type="Project" due={false} items={lateProjects} />
          )}
        </List>
      </DueLists>
    </TodayCard>
  );
};

Today.propTypes = {
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(Today));

const DueLists = styled.div`
  display: flex;
`;

const List = styled.div`
  width: 50%;
`;

const TodayCard = styled.div`
  ${props => props.theme.card}
  margin-bottom: var(--main-margin);
  white-space: normal;
`;
