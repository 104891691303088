import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faEllipsisV,
  faTrashAlt,
  // faCalendar,
} from '@fortawesome/free-solid-svg-icons';

const Project = ({ project, AppState }) => {
  const { changeOpenProject, getProjectProgress, removeProject } = AppState;

  // const [options, toggleOptions] = useState(false);
  const [progress, updateProgress] = useState(getProjectProgress(project.id));

  useEffect(() => {
    updateProgress(getProjectProgress(project.id));
  }, [project.todoLists, getProjectProgress, project.id]);

  return (
    <ProjectCard>
      <ProjectButton
        onClick={() => {
          changeOpenProject(project);
        }}
      >
        <Progress value={progress.completed} max={progress.total} />
        <Title>{project.title}</Title>
        {project.due !== '' && project.due < new Date().getTime() ? (
          <Due late>Late</Due>
        ) : new Date(project.due).toDateString() ===
          new Date().toDateString() ? (
          <Due today>Today</Due>
        ) : (
          project.due && (
            <Due>
              {`${new Date(project.due).getMonth() + 1}/${new Date(
                project.due
              ).getDate()}`}
            </Due>
          )
        )}
      </ProjectButton>
      <Option onClick={() => removeProject(project.id)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Option>
    </ProjectCard>
  );
};

Project.propTypes = {
  project: PropTypes.object,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(Project));

const Option = styled.button`
  cursor: pointer;
  padding: 0 0.6rem;
  background: var(--secondary-color);
  margin-left: 0.6rem;
  border-radius: var(--rounded);
  display: none;
  align-items: center;
  &:hover {
    & svg path {
      fill: var(--secondary-theme-color);
      transition: fill 0.3s linear;
    }
  }
`;

const ProjectCard = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: var(--main-margin);
  overflow: hidden;
  &:hover {
    ${Option} {
      display: flex;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ProjectButton = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  ${props => props.theme.card}
`;

const Progress = styled.progress`
  ${props => props.theme.progress};
  &::-webkit-progress-bar {
    border-radius: var(--rounded);
  }
`;

const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden !important;
`;

const Due = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0;
  color: ${props => {
    if (props.late) {
      return 'var(--secondary-theme-color)';
    } else if (props.today) {
      return 'var(--theme-color)';
    }
  }};
`;
