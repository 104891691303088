import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import arrow from '../../images/arrow.svg';

export default function CalendarInput(props) {
  const date = new Date();
  const [month, updateMonth] = useState(date.getMonth());
  const [today] = useState(date.getDate());
  const [year, updateYear] = useState(date.getFullYear());
  const actualMonth = date.getMonth();
  const actualYear = date.getFullYear();
  const { handleDue, toggle } = props;
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let days = [];

  const handleUpdateMonth = direction => {
    if (direction === 'ascend') {
      if (month === 11) {
        updateMonth(0);
        updateYear(year + 1);
      } else {
        updateMonth(month + 1);
      }
    }
    if (direction === 'descend') {
      if (month === 0) {
        updateMonth(11);
        updateYear(year - 1);
      } else {
        updateMonth(month - 1);
      }
    }
  };

  for (let i = 1; i <= daysInMonth; i++) {
    days = [...days, i];
  }

  function calendarMap(day) {
    if (day < today && actualMonth === month && actualYear === year) {
      if (day === 1) {
        return (
          <span key={day} style={{ gridColumnStart: firstDay + 1 }}>
            {day}
          </span>
        );
      }
      return <span key={day}>{day}</span>;
    }
    if (day === 1) {
      console.log(firstDay);
      return (
        <input
          type="button"
          value={day}
          key={day}
          style={{ gridColumnStart: firstDay + 1 }}
          onClick={() => {
            handleDue([month + 1, day, year].join('/'), 'cal');
            toggle();
          }}
        />
      );
    }
    return (
      <input
        type="button"
        value={day}
        key={day}
        onClick={() => {
          handleDue([month + 1, day, year].join('/'), 'cal');
          toggle();
        }}
      />
    );
  }

  // const onCurrentMonth =
    // actualMonth === month && actualYear === year && 'disabled';

  return (
    <CalendatInput>
      <CalendarControls>
        {actualMonth !== month && (
          <MonthButton
            type="button"
            onClick={() => handleUpdateMonth('descend')}
            back
          />
        )}
        <span>{`${months[month]}, ${year}`}</span>
        <MonthButton
          type="button"
          onClick={() => handleUpdateMonth('ascend')}
        />
      </CalendarControls>

      <Sheet>
        <Day>Sun</Day>
        <Day>Mon</Day>
        <Day>Tue</Day>
        <Day>Wed</Day>
        <Day>Thu</Day>
        <Day>Fri</Day>
        <Day>Sat</Day>
        {days.map(day => calendarMap(day))}
      </Sheet>
    </CalendatInput>
  );
}

CalendarInput.propTypes = {
  showing: PropTypes.bool,
  handleDue: PropTypes.func,
  toggle: PropTypes.func,
};

const CalendatInput = styled.div`
  overflow: hidden;
  margin-bottom: 1rem;
`;

const Sheet = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  & input {
    ${props => props.theme.button}
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: transparent;
    margin: auto;
    padding: 0.5rem;
    line-height: normal;
    color: var(--main-font-color);
    &:hover {
      background: var(--theme-color);
      color: var(--main-color);
    }
  }
  & span {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    color: var(--secondary-font-color);
    margin: auto;
    display: block;
    line-height: 2.5rem;
  }
`;

const Day = styled.span`
  color: var(--main-font-color);
`;

const CalendarControls = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  height: 2.5rem;
  & span {
    grid-column-start: 2;
    grid-column-end: 7;
    font-size: 1rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
`;

const MonthButton = styled.input`
  ${props => props.theme.button}
  background: var(--theme-color) url(${arrow}) center/40% no-repeat;
  ${props => props.back && `transform: rotate(180deg);`}
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: auto;
  font-weight: bolder;
`;
