import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Project from './Project';

const Projects = ({ AppState }) => {
  const { projects } = AppState;

  const filteredProjects = projects.filter(project => project.due !== '');
  const noDue = projects.filter(project => project.due === '');

  return (
    <div>
      {filteredProjects.map(project => (
        <Project project={project} key={project.id} />
      ))}
      {noDue.map(project => (
        <Project project={project} key={project.id} />
      ))}
    </div>
  );
};

Projects.propTypes = {
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(Projects));
