import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import Todo from './Todo';
import ListOptions from './ListOptions';

const TodoList = ({ list, projectId, listId, AppState }) => {
  const { addTodo, renameList, collapseList } = AppState;
  const [todo, changeTodo] = useState('');
  const [editing, toggleEditing] = useState(false);
  const [titleInput, updateTitleInput] = useState(list.title);
  const [todoLength, changeTodoLength] = useState(list.todos.length);
  const [completedLength, changeCompletedLength] = useState(
    list.todos.filter(item => item.completed === true).length
  );
  // const [collapsed, toggleCollapse] = useState(
  //   completedLength / todoLength === 1
  // );
  const [options, toggleOptions] = useState(false);
  const todos = list.todos.slice().sort(compare);

  // if (completedLength / todoLength === 1) {
  //   collapseList(projectId, listId);
  // }

  useEffect(() => {
    changeTodoLength(list.todos.length);
    changeCompletedLength(
      list.todos.filter(item => item.completed === true).length
    );
    // if (completedLength / todoLength === 1) {
    //   collapseList(projectId, listId);
    // }
  }, [list.todos]);

  const handleNewList = e => {
    e.preventDefault();
    if (todo.length > 0) {
      addTodo(projectId, listId, todo);
      changeTodo('');
    }
  };

  const handleListEdit = e => {
    e.preventDefault();
    toggleEditing(false);
    renameList(projectId, listId, titleInput);
  };

  function compare(a, b) {
    if (a.completed < b.completed) {
      return -1;
    } else if (a.completed > b.completed) {
      return 1;
    } else return 0;
  }

  return (
    <Card>
      {editing ? (
        <TodoForm onSubmit={handleListEdit}>
          <EditInput
            type="text"
            value={titleInput}
            autoFocus
            onChange={e => updateTitleInput(e.target.value)}
            onBlur={() => toggleEditing(false)}
          />
        </TodoForm>
      ) : (
        <h2 onClick={() => toggleEditing(true)}>{list.title}</h2>
      )}
      {list.due && (
        <ListDue>
          {`${new Date(list.due).getMonth() + 1}/${new Date(
            list.due
          ).getDate()}`}
        </ListDue>
      )}

      <ProgressContainer>
        <progress value={completedLength} max={todoLength} />
      </ProgressContainer>
      <ListOptionsContainer>
        {options && (
          <ListOptions
            list={list}
            listId={listId}
            projectId={projectId}
            toggleOptions={toggleOptions}
            currentDescription={list.description}
            completed={completedLength / todoLength}
          />
        )}
        <OptionsButton
          type="button"
          value="Show"
          onClick={() => toggleOptions(true)}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </OptionsButton>
        <CollapseButton
          type="button"
          value="Show"
          collapsed={list.collapsed}
          onClick={() => collapseList(projectId, listId)}
        >
          <FontAwesomeIcon icon={faCaretDown} />
        </CollapseButton>
      </ListOptionsContainer>
      {list.description && <p>{list.description}</p>}
      <List collapsed={list.collapsed}>
        {todos.map(todo => (
          <Todo
            todo={todo}
            projectId={projectId}
            listId={listId}
            key={todo.title + Math.random()}
          />
        ))}
        <NewTodo onSubmit={handleNewList}>
          <SubmitTodo type="submit" value="+" />
          <AddTodo
            type="text"
            name="addTodo"
            autoComplete="off"
            placeholder="Add todo"
            value={todo}
            onChange={e => changeTodo(e.target.value)}
          />
        </NewTodo>
      </List>
    </Card>
  );
};

TodoList.propTypes = {
  list: PropTypes.object,
  projectId: PropTypes.string,
  listId: PropTypes.string,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(TodoList));

const Card = styled.div`
  ${props => props.theme.card}
  margin-bottom: var(--main-margin);
  position: relative;
  background: var(--secondary-color);
  padding: calc(0.8rem + 5px) 0 0.8rem;
  & h2 {
    font-size: 1.1em;
    display: inline;
    padding-left: 0.8rem;
  }
  & p {
    display: inline-block;
    width: calc(100% - 5.4rem);
    margin: 0 0.8rem;
  }
`;

const ProgressContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: var(--rounded);
`;

const List = styled.ul`
  ${props =>
    props.collapsed &&
    `overflow: hidden;
    height: 0px;
    margin: 0;`}
`;

const ListOptionsContainer = styled.div`
  float: right;
  padding-right: 0.8rem;
  position: relative;
`;

const TodoForm = styled.form`
  display: inline;
  & input {
    width: 80%;
    font-size: 1.1em;
    border: none;
    outline: none;
    background-color: inherit;
    font-weight: bold;
  }
`;

const CollapseButton = styled.button`
  margin-left: 1em;
  transition: transform 0.2s ease-out;
  transform: ${props => (props.collapsed ? 'rotate(0deg)' : 'rotate(-180deg)')};
  width: 1rem;
  font-size: 1em;
  cursor: pointer;
  & svg path {
    fill: var(--main-font-color);
  }
`;

const OptionsButton = styled.button`
  font-size: 1em;
  cursor: pointer;
  & svg path {
    fill: gray;
  }
  display: inline;
`;

const NewTodo = styled.form`
  position: relative;
  margin: 0 var(--main-margin);
`;

const SubmitTodo = styled.input`
  ${props => props.theme.button}
  background: transparent;
  color: var(--theme-color);
  font-weight: bolder;
  padding: 0 0.2rem;
  position: absolute;
  height: 1em;
`;

const AddTodo = styled.input`
  background: inherit;
  border: none;
  outline: none;
  font-size: 1em;
  padding: 0 1.2em;
  width: 100%;
  &::-webkit-input-placeholder {
    color: var(--main-font-color);
  }
  &::-moz-placeholder {
    color: var(--main-font-color);
  }
  &:-ms-input-placeholder {
    color: var(--main-font-color);
  }
  &::-ms-input-placeholder {
    color: var(--main-font-color);
  }
  &::placeholder {
    color: var(--main-font-color);
  }
`;

const EditInput = styled.input`
  outline: none;
  border: none;
  overflow: visible;
  resize: none;
  background-color: transparent;
  margin-left: 1.4rem;
  width: calc(100% - 2.6rem);
`;

const ListDue = styled.span`
  display: inline;
  font-size: 0.8rem;
  color: gray;
  margin-left: 0.6rem;
`;
