import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { dateToday } from '../../functions/kemplet-date';
import CalendarInput from '../../CalendarInput';

const ProjectEdit = ({ project, id, toggle, AppState }) => {
  const { removeProject, editProject } = AppState;

  const dueDate = new Date(project.due);
  const [title, updateTitle] = useState(project.title);
  const [calendar, toggleCalendar] = useState(false);
  const [due, updateDue] = useState(
    project.due
      ? `${
          dueDate.getMonth() + 1
        }/${dueDate.getDate()}/${dueDate.getFullYear()}`
      : ''
  );
  const [time, updateTime] = useState(
    project.due
      ? `${dueDate.getHours()}:${('0' + dueDate.getMinutes()).slice(-2)}`
      : '23:59'
  );

  const [highlight, updateHighlight] = useState(1);
  const today = dateToday(0);
  const tomorrow = dateToday(1);

  const handleSubmit = e => {
    e.preventDefault();

    const submitDue = new Date(due);
    submitDue.setHours(Number(time.substr(0, 2)), Number(time.substr(3, 5)), 0);
    if (title && due === '') {
      editProject(id, title, '');
      toggle();
    } else if (title) {
      editProject(id, title, submitDue.getTime());
      toggle();
    }
  };

  const handleDueClick = (due, e, highlightClick) => {
    if (e === 'cal') {
      due === today && updateHighlight(2);
      due === tomorrow && updateHighlight(3);
      due > tomorrow && updateHighlight(0);
    } else {
      updateHighlight(highlightClick);
    }
    updateDue(due);
    toggleCalendar(false);
  };

  return (
    <div>
      <EditContainer>
        <InputMotion>
          <input
            type="text"
            name="title"
            autoComplete="off"
            value={title}
            onChange={e => updateTitle(e.target.value)}
            required
          />
          <InputTitle>Title:</InputTitle>
        </InputMotion>
        <DueDateAndTime>
          <InputMotion>
            <DateInput
              type="text"
              name="due"
              autoComplete="off"
              value={due}
              required
              onFocus={() => toggleCalendar(true)}
            />
            <InputTitle>Due:</InputTitle>
          </InputMotion>
          {due !== '' && (
            <InputMotion>
              <DateInput
                type="time"
                name="time"
                autoComplete="off"
                defaultValue={time}
                required
                onChange={e => updateTime(e.target.value)}
              />
              <InputTitle>At</InputTitle>
            </InputMotion>
          )}
        </DueDateAndTime>
        {calendar && (
          <CalendarInput
            showing={calendar}
            handleDue={handleDueClick}
            toggle={toggleCalendar}
          />
        )}
        <ButtonGroup highlight={highlight}>
          <input
            className="noneOption active"
            type="button"
            value="none"
            onClick={e => handleDueClick('', e, 1)}
          />
          <input
            className="todayOption"
            type="button"
            value="today"
            onClick={e => handleDueClick(today, e, 2)}
          />
          <input
            className="tomorrowOption"
            type="button"
            value="tomorrow"
            onClick={e => handleDueClick(tomorrow, e, 3)}
          />
        </ButtonGroup>
        <FormButton type="submit" value="save" onClick={handleSubmit} />
        <FormButton close type="button" value="don't save" onClick={toggle} />
        <FormButton
          close
          type="button"
          value="delete project"
          onClick={() => removeProject(id)}
        />
      </EditContainer>
      <Modal onClick={toggle} />
    </div>
  );
};

ProjectEdit.propTypes = {
  project: PropTypes.object,
  id: PropTypes.number,
  toggle: PropTypes.func,
  AppState: PropTypes.object,
};

export default inject('AppState')(observer(ProjectEdit));

const EditContainer = styled.form`
  ${props => props.theme.card}
  width: 22.5rem;
  padding: 2rem 1rem;
  z-index: 4;
  position: absolute;
  top: 0;
  left: calc(50% - 11.25rem);
  margin: 0;
  z-index: 4;
  text-align: center;
  & > span {
    font-size: 1em;
  }
  & > div {
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

const InputTitle = styled.span`
  position: absolute;
  font-size: 1.2em;
  color: var(--main-font-color);
  top: 0.4em;
  left: 0.4em;
  transition: all ease 0.08s;
  pointer-events: none;
`;

const InputMotion = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: 2rem;
  &:nth-child(2) {
    margin-left: 1rem;
  }
  & input {
    font-size: 1.2em;
    font-weight: bold;
    border: none;
    outline: none;
    width: 100%;
    background: inherit;
    background: var(--button-group-color);
    border-radius: var(--rounded);
    padding: var(--secondary-margin);
    &:focus,
    &:valid {
      & + ${InputTitle} {
        top: -1.4em;
        font-size: 0.8em;
        color: var(--secondary-font-color);
      }
    }
  }
`;

const DueDateAndTime = styled.div`
  display: flex;
`;

const DateInput = styled.input`
  color: var(--main-font-color);
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  border-radius: var(--rounded);
  overflow: hidden;
  background: var(--button-group-color);
  margin-bottom: 1rem;
  & input {
    ${props => props.theme.button}
    background: transparent;
    border-radius: var(--rounded);
    color: var(--main-font-color);
  }
  & input:nth-child(${props => props.highlight}) {
    background: var(--theme-color);
    color: white;
  }
`;

const FormButton = styled.input`
  ${props => props.theme.button};
  ${props =>
    props.close &&
    `
     background: var(--secondary-theme-color); 
     margin-top: 1rem;`}
  font-size: 1em;
  width: 100%;
`;
